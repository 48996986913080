import dayjs from "dayjs"
import { take } from "lodash-es"
import { defineStore } from "pinia"

export interface RecentCheckedData {
  typedPk: string, category: string, title: string, imgSrc: string, insertedTime: string, toSrc: string
}

export const useDYRecentCheckedStore = defineStore('nuxt3-module-doyac-recent-checked', () => {
  //로컬 스토리지 값을 스토어 방식으로 동작시킴. 이 경우 알아서 parsing됨
  const recentTypedPk = ref<RecentCheckedData[]>()

  const setRecentChecked = (data: RecentCheckedData) => { //로컬 스토리지를 사용하기 때문에 ssr에선 못 씀
    // const localGetRecent = localStorage.getItem('recentTypedPk')
    // let recentArr:RecentCheckedData[] = localGetRecent ? JSON.parse(localGetRecent) : []

    //똑같은 아이템을 여러번 중복 넣는 것을 방지 위함
    let recentArr: RecentCheckedData[] = []
    if (recentTypedPk.value) {
      recentArr = recentTypedPk.value?.filter((item) => { //set시점에 일주일 내에 것만 담고 나머진 삭제처리
        return item.typedPk !== data.typedPk && dayjs().subtract(7, 'day').isBefore(item.insertedTime)
      })
    }

    recentArr.unshift(data) //최근에 본 아이템이 가장 앞에 담김
    if (recentArr.length > 20) recentArr.pop() //아이템은 최고 20개만 보관함

    recentTypedPk.value = recentArr
    // localStorage.setItem('recentTypedPk', JSON.stringify(recentArr))
  }

  const getRecentChecked = (limit?: number): RecentCheckedData[] | undefined => {
    // const getRecent = localStorage.getItem('recentTypedPk')
    if (recentTypedPk.value) {
      // let parsed = JSON.parse(getRecent)  
      const parsed = recentTypedPk.value.filter((item: RecentCheckedData) => { //최근 일주일 것만 보여줌
        return dayjs().subtract(7, 'day').isBefore(item.insertedTime)
      })

      return limit ? take(parsed, limit) : parsed
    }
    return undefined
  }

  const removeRecentChecked = () => {
    return recentTypedPk.value = undefined
  }

  return {
    setRecentChecked, getRecentChecked, removeRecentChecked, recentTypedPk
  }
}, {
  persist: { //로컬 스토리지 값을 스토어로 쓰기 위함
    storage: persistedState.localStorage
  }
})